// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
// @import "variables";

@import "variables2";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "bootswatch";

body {
    overflow-x: hidden;

    &.general {

        #tasksList {

            .form-horizontal {
                display: block;
            }
        }
    }

    .nav {

        > li {

            > a {
                padding: 10px;
                font-size: 13px;
            }
        }
    }
}

.formFieldset {
  margin-bottom: 20px;

  .formRow {
    margin-bottom: 10px;

    &.halfWidth {
      width: 50%;
      float: left;
      margin: 20px 0 10px 0;

      @media (max-width: 900px) {
        width: 100%;
      }

      label {
        margin-bottom: 15px;
      }
    }
  }

  label {
    font-weight: normal;
  }
}

table {
  width: 100%;
  margin: 20px 0 30px 0;

  &.halfWidth {
    width: 50%;
    float: left;


    @media (max-width: 900px) {
      width: 100%;
    }
  }

  th {
    font-weight: normal;
    text-align: center;

    &.thCol {
      padding-bottom: 10px;
    }

    &.thRow {
      padding-right: 15px;
      width: 90px;
    }
  }

  td {
    padding: 5px;
  }
}

.formFields {
    overflow: hidden;

    .formButton {
        margin: 10px 0 0;

        .btn {
            float: right;
            width: 100%;
            max-width: 120px;
            margin-left: 10px;
        }
    }
}

.taskList {
    margin: 20px 0 0;
}

.taskItem {
    position: relative;
    border: 1px solid #e5e5e5;
    padding: 20px 20px 37px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        margin: 0 0 10px;
    }

    .btn {
        position: absolute;
        right: -1px;
        bottom: -1px;
        border-radius: 0;

        &.btn-info {
            right: 80px;
        }
    }

    .taskDate {
        color: #666;
    }
}

.tasksWrap {
    margin: 40px 0;

    h4 {
        position: relative;
    }

    .formFields {
        display: none;
    }
}

.buttonAddTask {
    position: absolute;
    right: 0;
    top: -20px;
    width: 100%;
    max-width: 120px;
}

.panel.panel-alternate {
    background: #efefef;
    border-radius: 8px;
}

.panel-body {
    padding: 15px;
}

.panel-alternate .panel-body {
    padding: 20px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-alternate .panel-heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff;
    color: #39393a;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    float: left;
    width: 100%;
    padding: 17px 20px 7px;
    border-bottom: 1px solid #cacaca;
}

.panel-alternate .panel-title {
    float: left;
    margin: 0 20px 10px 0;
}

.panel-alternate .date {
    float: right;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.1;
}

.panel-alternate {
    border-color: #ababab;
    background: #fff;
    color: #39393a;
}

.dashboardFormWrap {
    max-width: 1840px;
    margin: 30px auto 0;
    width: 100%;
}

.fullWidth {
    width: 100%;
    float: left;
}

.noPadding {
    padding: 0;
}

.reportsWrap .col-md-2, .reportsWrap .col-md-3, .reportsWrap .col-md-4 {
    padding-left: 5px;
    padding-right: 5px;
}

.reportsWrap {
    float: left;
    width: 100%;
    border-bottom: 1px solid #cacaca;
    padding: 20px 0;
}

.marginBottom .report {
    margin: 0 0 7px;
}

.reportsWrap span {
    display: block;
    font-size: 14px;
    color: #4a4a4a;
    line-height: 16px;
}

.reportsWrap .number {
    font-size: 28px;
    color: #39393a;
    line-height: 1;
}

.noBorderBottom {
    border-bottom: none;
}

.totalWrap {
    border-radius: 8px;
    text-align: center;
    padding: 16px 20px;
    color: #fff;
    margin: 0 0 25px;
    float: left;
    width: 100%;
}

.totalWrap.alnwick {
    background: #e3001c;
}

.totalWrap.morpeth {
    background: #4a90e2;
}

.totalWrap .totalNumber {
    font-size: 80px;
    display: block;
    font-weight: 700;
    line-height: 1;
}

.totalWrap .totalTitle {
    font-size: 20px;
    font-weight: 700;
    display: block;
}

.googleReviewsWrap {
    margin: 0 0 20px;

    .eapps-widget-toolbar {
        display: none;
    }

    [class*="BadgeLayout__Container"] {
        margin: 0;
    }

    [class*="BadgeLayout__Inner"] {
        flex: 1;
    }
}

.rowOfSeven .report {
    width: calc(100% / 7);
}

.reportHeader {
    margin: 0 0 10px;
}

.panel-large {
    min-height: 543px;
}

table.formRow {
    border: 1px solid #ddd;

    th.thCol {
        text-align: left;
        padding: 5px 10px;
        font-weight: bold;
        border: 1px solid #ddd;
    }

    td {
        padding: 5px 10px;
        border: 1px solid #ddd;
    }

    tbody tr:nth-child(odd) {
        background: #f5f5f5;
    }

    .btn {
        display: block;
    }
}

.panel-heading-button {
    overflow: hidden;

    span {
        margin: 9px 0;
        display: inline-block;
    }
}

#recallform {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.dashboardPanel {
    background: #FFF;
    border-radius: 8px;
}

.dashboardHeader {
    text-align: right;
    width: 100%;
    margin: 0 0 10px;

    &:first-child {
        padding-top: 16px;
    }
}

.dashboardTable {
    font-size: 18px;
    line-height: 1.333;
    text-align: left;
    border-collapse: separate;
    border-spacing: 4px;
    margin: 10px -14px;
    width: calc(100% + 28px);

    &.registrationsTable {
        text-align: center;
        margin: 10px -14px 10px -25px;
        width: calc(100% + 39px);

        tr:first-child td:first-child {
            border-left: 1px solid #ababab;
        }

        td {
            padding: 2px;

            &:first-child {
                width: 18%;
            }

            &:last-child {
                width: 20%;
            }
        }
    }

    &.inputTable {

        tr:first-child td:first-child {
            height: 156px;

            .fullHeightInputRow {
                display: flex;
                min-height: 100%;

                input {
                    height: auto;
                }
            }
        }

        td {
            background: none;
            padding: 0;
            border: 0;
        }
    }

    th {
        text-align: left;
        font-weight: bold;
    }

    td {
        border: 1px solid #CCC;
        padding: 5px;
        background: #efefef;

        &.noBorder {
            border-color: transparent;
            background: none;
        }
    }

    hr {
        margin: 0 -2px;
        border-color: #CCC;
    }
}

.nav {

    .mainLink {
        &:hover {
            background: #515151;

            .dropdown-custom {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .dropdown-custom {
        position: absolute;
        top: 100%;
        z-index: 8;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #ccc;
        min-width: 150px;
        left: 0;
        overflow: hidden;
        pointer-events: none;
        opacity: 0;
        transition: all 0.5s;

        @media (max-width: 768px) {
            position: static;
            opacity: 1;
            border: 0;
            border-radius: 0;
            pointer-events: all;
        }

        a {
            display: flex;
            padding: 7px 10px;
            transition: all 0.3s;

            &:hover {
                text-decoration: none;
                background: #eee;
            }
        }
    }
}

#figures {
    legend {
        margin-bottom: 0;
    }
}

legend {
    padding-bottom: 7px;
}

.tabs {
    background: #f5f5f5;
    display: flex;
    border: 1px solid #ddd;
    margin-bottom: 30px;

    .tab {
        border-right: 1px solid #ddd;
        padding: 10px 18px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            background: #ccc;
        }

        &.active {
            background: #ddd;
        }
    }
}

.tabContent {
    display: none;
}

.documentsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;

    &__msg {
        color: #fff;
        padding: 7px 14px;
        text-align: center;
        margin-bottom: 15px;
        display: none;

        &__success {
            background: $brand-success;
        }

        &__error {
            background: $brand-danger;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    &__form {
        border: 1px solid #e5e5e5;
        padding: 25px;
        display: none;

        &--visible {
            display: block;
        }
    }

    &__inputWrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1002;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 500px;
        max-height: 75vh;
        overflow: auto;
        background: #fff;
        padding: 60px 30px 30px;

        h2 {
            margin: 0 0 25px;

            legend {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .thCol {
            background: #fff;
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 32px;
        height: 32px;
        opacity: 1;
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }

    &__close::before,
    &__close::after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #000;
    }

    &__close::before {
        transform: rotate(45deg);
    }

    &__close:after {
        transform: rotate(-45deg);
    }
}

.no-sort {
    pointer-events: none !important;
    cursor: default !important;

    &::before,
    &::after {
        display: none !important;
    }
}

.docsTable {

    th {
        line-height: 1;
    }
}

.docTitle {
    margin: 0 0 25px;

    legend {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
